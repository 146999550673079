import { ErrorBoundary } from 'Providers/ErrorBoundary/ErrorBoundary'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import './index.css'
// import reportWebVitals from "./reportWebVitals";
import { datadogRum } from '@datadog/browser-rum'
import { PWAProvider } from 'Providers/PWAProvider/PWAProvider'
import { UIStateProvider } from 'Providers/UIStateProvider'
import { myTheme } from 'theme'
import './reset.css'

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_APP_ENV === 'PRODUCTION') {
  console.log = () => {}
  console.count = () => {}
  console.time = () => {}
  console.timeEnd = () => {}
  console.table = () => {}
}

export const bootstrapDatadog = () => {
  datadogRum.init({
    applicationId: '512e08d7-7cdf-4ab5-a40d-c8936471eb31',
    clientToken: 'pub74f71c91950d84edbd90bf7c14ebf0c4',
    site: 'datadoghq.com',
    env: process.env.REACT_APP_APP_ENV,
    service: 'splurv',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask',
  })

  datadogRum.startSessionReplayRecording()

  datadogRum.addRumGlobalContext('viewport', {
    width: window.innerWidth,
    height: window.innerHeight,
  })
}

const main = () => {
  try {
    bootstrapDatadog()
  } catch (err) {
    alert('error bootstrapping datadog')
    console.log('error bootstrapping datadog', err)

    ReactDOM.render(
      <React.StrictMode>
        <h1 style={{ color: 'black' }}>something terrible has happened with datadog</h1>
      </React.StrictMode>,
      document.getElementById('root')
    )
  }
}

main()

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <ThemeProvider theme={myTheme}>
          <UIStateProvider>
            <PWAProvider />
          </UIStateProvider>
        </ThemeProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
