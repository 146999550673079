import React from 'react'
import { Transition } from 'react-spring/renderprops'
import { ThemeProvider } from 'styled-components/macro'
import { colorPalletes, myTheme } from 'theme'
import { useUIState } from '../../Providers/UIStateProvider'
import { Icon } from '../DesignSystem/Typography'
import { Content } from './PopupComponent.components'

interface Props {
  customColorPallete?: keyof typeof colorPalletes
}
export const PopupComponent: React.FC<Props> = ({ customColorPallete }) => {
  const { popup, setPopup } = useUIState()

  // const firstClick = useRef<boolean>(true)

  // React.useEffect(() => {
  //   const clickClose = (event: MouseEvent) => {
  //     const specifiedElement = document.getElementById('popup-message')

  //     // @ts-ignore
  //     const isClickInside = specifiedElement?.contains(event?.target)
  //     if (!isClickInside && messagePopupContent && !firstClick.current) {
  //       console.log('outside click!!!!!!!!!')
  //       setMessagePopupContent('')
  //       firstClick.current = true
  //     } else {
  //       firstClick.current = false
  //     }
  //   }
  //   document.addEventListener('click', clickClose)

  //   return () => {
  //     document.removeEventListener('click', clickClose)
  //   }
  //   // eslint-disable-next-line
  // }, [messagePopupContent])

  return (
    <Transition
      items={!!popup}
      from={{ opacity: 1, transform: 'translate3d(0,100%,0)' }}
      enter={{ opacity: 1, transform: 'translate3d(0,0%,0)' }}
      leave={{ opacity: 1, transform: 'translate3d(0,100%,0)' }}>
      {(show) =>
        show &&
        ((props) => (
          <ThemeProvider
            theme={{ ...myTheme, colors: colorPalletes[customColorPallete || 'newLight'] }}>
            <Content id="popup-message" data-testid="floating-message-popup" style={props}>
              <div
                style={{
                  position: 'absolute',
                  top: '1rem',
                  right: '1rem',
                }}
                data-testid="dismiss-popup-button"
                onClick={() => {
                  setPopup(null)
                }}
                className="d-flex align-items-center justify-content-end w-100">
                <Icon
                  size="xxl"
                  color="text"
                  style={{ opacity: !!popup ? 1 : 0 }}
                  // style={{ position: 'absolute', right: '1.5rem', top: '.5rem' }}
                  className="fas fa-times"
                />
              </div>
              {popup}
            </Content>
          </ThemeProvider>
        ))
      }
    </Transition>
  )
}
