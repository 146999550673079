import { useAuth0 } from '@auth0/auth0-react'
import { datadogRum } from '@datadog/browser-rum'
import { useUIState } from 'Providers/UIStateProvider'
import React, { useCallback, useState } from 'react'
import { useGetAccessToken } from './useGetAccessToken'

export type MethodType = 'GET' | 'POST' | 'DELETE'
interface Params<BodyParamsShape extends Object, ResponseShape> {
  route: string
  method: MethodType
  fireOnMount?: boolean
  cachedData?: ResponseShape
  body?: BodyParamsShape
}

interface LoadingResponse<ResponseShape> {
  status: 'loading'
  error: null
  data: null | ResponseShape
}

interface ErrorResponse {
  status: 'error'
  error: string
  data: null
}

interface SuccessResponse<ResponseShape> {
  status: 'done'
  error: null
  data: ResponseShape
}

interface InitialState<ResponseShape> {
  status: 'idle'
  error: null
  data: null | ResponseShape
}

export type AsyncHookResponse<ResponseShape> =
  | LoadingResponse<ResponseShape>
  | ErrorResponse
  | SuccessResponse<ResponseShape>
  | InitialState<ResponseShape>

interface HookReturn<
  ResponseShape,
  BodyParamsShape extends Object,
  QueryParamsShape extends Object
> {
  issueRequest: (params: {
    queryParamsConfig?: QueryParamsShape
    bodyParamsConfig?: BodyParamsShape
  }) => Promise<any>
  res: AsyncHookResponse<ResponseShape>
}

export function useAsyncFetch<
  ResponseShape,
  QueryParamsShape extends Object,
  BodyParamsShape extends Object = {}
>(
  config: Params<QueryParamsShape, ResponseShape>
): HookReturn<ResponseShape, BodyParamsShape, QueryParamsShape> {
  const [state, setState] = useState<AsyncHookResponse<ResponseShape>>({
    error: null,
    status: 'idle',
    data: null,
    ...(config.cachedData || {}),
  } as InitialState<ResponseShape>)

  const { user } = useAuth0()
  const { getAccessToken } = useGetAccessToken(`useAsyncFetch-${config.route}-${config.method}}`)

  const { setErrorPopupContent } = useUIState()
  // const setErrorPopupContent = (content: string) => console.debug(content)

  const issueRequest = useCallback(
    async ({
      queryParamsConfig,
      bodyParamsConfig,
    }: {
      queryParamsConfig?: QueryParamsShape
      bodyParamsConfig?: BodyParamsShape
    }) => {
      const now = window.performance.now()
      setState(
        (state) => ({ ...state, status: 'loading', error: null } as LoadingResponse<ResponseShape>)
      )
      const token = await getAccessToken()

      // NOTE: This kind of logic should belong in the token fetching which should have already happened
      // if (!isAuthenticated || !user) {
      //   loginWithRedirect({
      //     redirectUri: `${window.location.origin}${window.location.pathname}`,
      //   })
      // }

      const queryParams = queryParamsConfig
        ? `&${Object.entries(queryParamsConfig)
            .map(([key, value]) => `${key}=${value}`)
            .join('&')}`
        : ``

      try {
        if (!user && !user?.sub) {
          throw new Error('User not authenticated')
        }
        const userid = user.sub
        const url = `${process.env[`REACT_APP_${process.env.REACT_APP_APP_ENV}_API_URL`]}/${
          config.route
        }?userid=${userid}${queryParams}`

        const response = await fetch(url, {
          method: config.method,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyParamsConfig),
          mode: 'cors',
          credentials: 'include',
        })

        if (response.status !== 200) {
          const text = await response.text()
          setErrorPopupContent(
            `Error<>${config.method}-${config.route}: ${response.status} ${response.statusText}<>${text}`
          )
          setState(
            (state) => ({ ...state, error: text as unknown, status: 'error' } as ErrorResponse)
          )

          const end = window.performance.now()
          datadogRum.addAction('api-request', {
            'api-request': {
              path: `${config.route} - ${config.method}`,
              time: end - now,
              status: response.status,
            },
          })
          console.debug(`PERF ${config.method}-${config.route} took ${end - now}ms`)
          throw new Error(text)
        }

        const json = await response.json()
        setState(
          (state) =>
            ({
              ...state,
              data: (json as unknown) as ResponseShape,
              status: 'done',
              error: null,
            } as SuccessResponse<ResponseShape>)
        )

        const end = window.performance.now()
        datadogRum.addAction('api-request', {
          'api-request': {
            path: `${config.route} - ${config.method}`,
            time: end - now,
            status: response.status,
          },
        })
        console.debug(`PERF ${config.method}-${config.route} took ${end - now}ms`)
        return json
      } catch (err) {
        console.error(`Error: useAsyncFetch: ${config.route} ${String(err)}`)
        setState(
          (state) => ({ ...state, data: null, status: 'error', error: err as any } as ErrorResponse)
        )
        setErrorPopupContent(`Error<>${config.method}-${config.route}: ${err} `)
        throw new Error(String(err))
      }
    },
    // eslint-disable-next-line
    [user, config.method, config.route, getAccessToken]
  )

  React.useEffect(() => {
    if (config.fireOnMount) {
      issueRequest({})
    }
  }, [config.fireOnMount, issueRequest])

  return { issueRequest, res: state }
}

export function useAsyncFetchUnauthenticated<
  ResponseShape,
  QueryParamsShape extends Object,
  BodyParamsShape extends Object = {}
>(
  config: Params<QueryParamsShape, ResponseShape>
): HookReturn<ResponseShape, BodyParamsShape, QueryParamsShape> {
  const [state, setState] = useState<AsyncHookResponse<ResponseShape>>({
    error: null,
    status: 'idle',
    data: null,
    ...(config.cachedData || {}),
  } as InitialState<ResponseShape>)

  const { setErrorPopupContent } = useUIState()

  const issueRequest = useCallback(
    async ({
      queryParamsConfig,
      bodyParamsConfig,
    }: {
      queryParamsConfig?: QueryParamsShape
      bodyParamsConfig?: BodyParamsShape
    }) => {
      const now = window.performance.now()
      setState(
        (state) => ({ ...state, status: 'loading', error: null } as LoadingResponse<ResponseShape>)
      )

      const queryParams = queryParamsConfig
        ? `${Object.entries(queryParamsConfig)
            .map(([key, value]) => `${key}=${value}`)
            .join('&')}`
        : ``

      try {
        const url = `${process.env[`REACT_APP_${process.env.REACT_APP_APP_ENV}_API_URL`]}/${
          config.route
        }?${queryParams}`

        const response = await fetch(url, {
          method: config.method,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyParamsConfig),
          mode: 'cors',
          credentials: 'include',
        })

        if (response.status !== 200) {
          const text = await response.text()
          setErrorPopupContent(
            `Error<>${config.method}-${config.route}: ${response.status} ${response.statusText}<>${text}`
          )
          setState(
            (state) => ({ ...state, error: text as unknown, status: 'error' } as ErrorResponse)
          )

          const end = window.performance.now()
          datadogRum.addAction('api-request-unauthenticated', {
            'api-request-unauthenticated': {
              path: `${config.route} - ${config.method}`,
              time: end - now,
              status: response.status,
            },
          })
          console.debug(`PERF ${config.method}-${config.route} took ${end - now}ms`)
          throw new Error(text)
        }

        const json = await response.json()
        setState(
          (state) =>
            ({
              ...state,
              data: (json as unknown) as ResponseShape,
              status: 'done',
              error: null,
            } as SuccessResponse<ResponseShape>)
        )

        const end = window.performance.now()
        datadogRum.addAction('api-request-unauthenticated', {
          'api-request-unauthenticated': {
            path: `${config.route} - ${config.method}`,
            time: end - now,
            status: response.status,
          },
        })
        console.debug(`PERF ${config.method}-${config.route} took ${end - now}ms`)
        return json
      } catch (err) {
        console.error(`Error: useAsyncFetchUnauthenticated: ${config.route} ${String(err)}`)
        setState(
          (state) => ({ ...state, data: null, status: 'error', error: err as any } as ErrorResponse)
        )
        setErrorPopupContent(`Error<>${config.method}-${config.route}: ${err} `)
        throw new Error(String(err))
      }
    },
    // eslint-disable-next-line
    [config.method, config.route]
  )

  React.useEffect(() => {
    if (config.fireOnMount) {
      issueRequest({})
    }
  }, [config.fireOnMount, issueRequest])

  return { issueRequest, res: state }
}
