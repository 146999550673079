// my-theme.ts
export const darkColors = {
  primary: '#31B26E',
  second: '#4F8493',
  logoSecond: '#3B6B7D',
  logoSecondary: '#b6d8e3',
  logoPrimary: '#83FFB3',
  main: '#5BB174',
  seconddark: '#73A9B7',

  text: '#FBFCFE',

  white: '#FFFFFF',
  texttransparent: '#FBFCFE99',
  textsupertransparent: '#FBFCFE4D',

  background: '#2D2E30',
  nearlyclear: '#3B3B3D',
  backgrounddark: '#9599A2',

  backgroundPressed: '#292A2B',
  secondary: '#3D6874',
  cashgreen: '#85FFA7',
  black: 'black',
  cashgreenlight: '#9DFFCAB3',
  mehreddark: '#D73340',
  mehred: '#EA6A5B',
  yellow: '#BB9F06',
  mehredlight: '#FFABAB',

  secondBright: '#3B8AA9',
  orange: '#FFA500',
  orangeTransparent: '#FFA50080',
  orangeLight: '#EDBA53',
  boldsand: '#E19740',
  megapurple: '#F8A4FF',
  softblue: '#6CCFF6',

  podiumFirstFg: '#C9AC34',
  podiumFirstBg: '#4D472C',

  podiumSecondFg: '#D5D5D5',
  podiumSecondBg: '#54565D',

  podiumThirdFg: '#E2B170',
  podiumThirdBg: '#6A5541',
}

export const lightColors = {
  primary: '#31B26E',
  second: '#2A6D7F',

  logoSecondary: '#b6d8e3',

  orangeTransparent: '#FFA50080',
  logoSecond: '#3B6B7D',
  logoPrimary: '#83FFB3',
  main: '#5BB174',
  seconddark: '#4F8493',

  // nearlyclear: 'rgba(0,0,0,.2)',
  nearlyclear: '#F3F5EC',
  text: '#2D2E30',
  secondBright: '#3B8AA9',

  white: 'black',
  black: '#2D2E30',
  texttransparent: '#969696',
  textsupertransparent: '#B6B6B6',

  background: '#F3F5EC',
  backgrounddark: '#90918A',
  backgroundPressed: '#90918A',

  secondary: '#3D6874',
  cashgreen: '#3B8AA9',
  cashgreenlight: '#31B26E',

  mehreddark: '#B81E27',
  mehred: '#FF645C',

  yellow: '#909020',

  mehredlight: '#FFABAB',

  orange: '#CE8500',
  orangeLight: '#FFA500',
  boldsand: '#E19740',
  megapurple: '#B041B9',
  softblue: '#64BEB3',

  podiumFirstFg: '#B39F48',
  podiumFirstBg: '#37342B',

  podiumSecondFg: '#D5D5D5',
  podiumSecondBg: '#54565D',

  podiumThirdFg: '#BF905C',
  podiumThirdBg: '#6A5541',
}

export const newColorsLight = {
  ...lightColors,
  primary: '#98C648',
  second: '#3B8AA9',
  secondBright: '#3B8AA9',
  background: '#FEFFF7',
  backgrounddark: '#E2E3DB',
  text: '#484A54',
}

export const newColorsDark = {
  ...darkColors,
  primary: '#98C648',
  second: '#3B8AA9',
  secondBright: '#42BDFF',
  background: '#2D3142',
  backgrounddark: '#1B1F2F',
  backgroundPressed: '#1D2131',
  text: '#F4F4F6',
}

export const colorPalletes = {
  dark: darkColors,
  light: lightColors,
  newLight: newColorsLight,
  newDark: newColorsDark,
}

export const myTheme = {
  borderRadius: '5px',

  sizes: {
    banner: '3rem',
    large: '2rem',
    paragraph: '1rem',
  },
  colors: newColorsDark,
  fonts: {
    family: {
      primary: 'PhosphateSolid',
      secondary: 'Rubik, sans-serif',
    },
    weights: {
      100: 100,
      200: 200,
      300: 300,
      400: 400,
      500: 500,
      600: 600,
      700: 700,
      800: 800,
      900: 900,
    },
    sizes: {
      xxxxs: '.575rem',
      xxxs: '.625rem',
      xxs: '.75rem',
      xs: '.875rem',
      sm: '1rem',
      smm: '1.125rem',
      md: '1.25rem',
      lg: '1.5rem',
      xl: '2rem',
      xxl: '2.5rem',
      xxxl: '3.5rem',
      xxxxl: '4.5rem',
      xxxxxl: '5.5rem',
    },
  },
  footer: {
    buttonDiameter: 4, // in REM (left as number for JS calculations)
    buttonDiameterSmall: 3.5, // in REM (left as number for JS calculations)
    buttonBorderRadius: '3px', // in REM (left as number for JS calculations)
    indicatorWidth: 3, // in REM (left as number for JS calculations)
    indicatorHeight: '3px', // in REM (left as number for JS calculations)
  },
}

export type ThemeType = typeof myTheme

export type ColorType = keyof typeof myTheme.colors
export type FontSizeType = keyof typeof myTheme.fonts.sizes
export type FontWeightType = keyof typeof myTheme.fonts.weights
export type FontFamilyType = keyof typeof myTheme.fonts.family
