import {
  Icon,
  StyledHr,
  StyledVr,
  TextBold,
  TextBoldFancy,
  TextSlim,
  TextSpan,
} from 'Components/DesignSystem/Typography'
import { PopupComponent } from 'Components/PopupComponent/PopupComponent'
import { useUIState } from 'Providers/UIStateProvider'
import React from 'react'
import {
  InstallButton,
  PageContainer,
  ScreenshotCarousel,
  ScreenshotSlot,
  WelcomeContainer,
} from '../PWAcomponents'

import splurvLogo512 from 'Logos/logo512.png'

import { LatestGitInfo } from 'Components/LatestGitInfo.tsx/LatestGitInfo'

import { datadogRum } from '@datadog/browser-rum'
import { AppDarkOverlay } from 'App/components'
import { SPLURV_LOCAL_STORAGE_PREFIX } from 'Hooks/useLocalStorageSync'
import { isMobile } from 'Utilities/utils'
import qs from 'qs'
import { FontSizeType } from 'theme'
import { SplurvBetaSignupPopup } from './SplurvBetaSignupPopup'
import { fireConfetti } from './confetti'
import graph from './screenshots/webp/graph-new.webp'
import impulse from './screenshots/webp/impulse-new.webp'
import main from './screenshots/webp/main-new.webp'
import mission from './screenshots/webp/mission-new.webp'
import onboarding from './screenshots/webp/onboarding-new.webp'
import reflection from './screenshots/webp/reflection-new.webp'
import spending from './screenshots/webp/spending-new.webp'

export const AppLandingPage: React.FC = () => {
  const { setPopup, popup } = useUIState()

  const mobileDevice = isMobile()

  const titleSize: FontSizeType = mobileDevice ? 'sm' : 'xl'
  const bodySize: FontSizeType = mobileDevice ? 'xs' : 'md'

  React.useEffect(() => {
    const inviteQueryParam = qs.parse(window.location.search, { ignoreQueryPrefix: true })
      .invitedUser
    if (inviteQueryParam) {
      localStorage.setItem(
        `${SPLURV_LOCAL_STORAGE_PREFIX}__invitedUser`,
        inviteQueryParam as string
      )

      datadogRum.addRumGlobalContext('invitedUser-queryStorage', {
        invitedUser: inviteQueryParam,
        location: 'app-landing-page',
      })
    }
  }, [])

  const [showVersionInfo, setShowVersionInfo] = React.useState(false)
  if (window.location.pathname.includes('newauthpage')) {
    return (
      <WelcomeContainer data-testid="welcome-to-splurv-message">
        <PageContainer>
          <img
            alt=""
            src={splurvLogo512}
            style={{ width: '64px', height: '64px', borderRadius: '8px' }}
          />
          <TextBoldFancy size="xxxl" color="second" className="mt-3">
            Splurv
          </TextBoldFancy>
          <TextSlim weight={700} color="second" size="sm" className="mt-4">
            The money app about time.
          </TextSlim>
          <TextSlim color="second" size="md" className="mt-2">
            <b>Are you here a second time?</b>
          </TextSlim>
          <TextSlim color="second" size="sm">
            You were probably connecting a bank account and went through a few redirects and ended
            up here again. We'll save you the long technical explanation of what's happening, but
            worry not, this is a known issue we are trying to solve.
          </TextSlim>

          <TextSlim color="second" size="sm">
            Don't add the app a second time, but instead just go back to the app you previously
            added as a bookmark to your homescreen and the Plaid linking process will finish.
          </TextSlim>

          <TextSlim color="second" size="sm">
            Thanks for the patience while we try to blaze a path forward in the industry where apps
            can be made without the app store.
          </TextSlim>

          <StyledHr color="textsupertransparent" className="my-2" style={{ opacity: 0.2 }} />
        </PageContainer>
      </WelcomeContainer>
    )
  }
  return (
    <WelcomeContainer data-testid="welcome-to-splurv-message" pauseScroll={!!popup}>
      <PageContainer pauseScroll={!!popup}>
        <AppDarkOverlay active={!!popup} />
        <PopupComponent />
        <section>
          <div className="d-flex align-items-center w-100 flex-row">
            <img
              alt=""
              src={splurvLogo512}
              style={{
                width: '64px',
                height: '64px',
                borderRadius: '10px',
              }}
              className="mr-4"
            />

            <div className="d-flex align-items-start flex-column">
              <TextBold
                onClick={() => {
                  setShowVersionInfo((vi) => !vi)
                }}
                size="xxl"
                color="text"
                className="m-0 p-0">
                Splurv
              </TextBold>
              <div className="d-flex align-items-center flex-row">
                <TextSpan size="xs" color="textsupertransparent" className="mt-2">
                  splurv.io
                </TextSpan>
                {/* show this for personal usage */}
                <div
                  className="d-flex flex-row align-items-center"
                  style={{ opacity: showVersionInfo ? 1 : 0, transition: 'all .3s ease-in-out' }}>
                  <StyledVr color="textsupertransparent" className="mx-2" />
                  <TextSpan size="xxxxs" color="textsupertransparent" className="mt-2">
                    <LatestGitInfo />
                  </TextSpan>
                </div>
              </div>
            </div>
          </div>
          <InstallButton
            className="mt-4"
            onClick={async () => {
              fireConfetti({ duration: 0.3 })
              setPopup(<SplurvBetaSignupPopup />)
            }}>
            <Icon className="fad fa-party-horn" size="lg" color="softblue" />
            <TextSpan
              size="md"
              color="softblue"
              className="ml-2 text-center"
              weight={300}
              style={{
                letterSpacing: '1.5px',
                textTransform: 'uppercase',
              }}>
              join the list
            </TextSpan>
          </InstallButton>

          <ScreenshotCarousel>
            <ScreenshotSlot src={main} />
            <ScreenshotSlot src={onboarding} />
            <ScreenshotSlot src={graph} />
            <ScreenshotSlot src={spending} />
            <ScreenshotSlot src={impulse} />
            <ScreenshotSlot src={reflection} />
            <ScreenshotSlot src={mission} />
          </ScreenshotCarousel>
          <TextSpan
            style={{ display: 'inline' }}
            weight={500}
            color="logoSecondary"
            size={mobileDevice ? 'md' : 'lg'}
            className={`align-self-start px-${mobileDevice ? 3 : 5} text-center my-${
              mobileDevice ? 1 : 2
            }`}>
            Splurv is a personal finance app that brings{' '}
            <TextSpan weight={700} className="my-0" color="logoPrimary">
              time
            </TextSpan>{' '}
            and{' '}
            <TextSpan weight={700} className="my-0" color="logoPrimary">
              purpose
            </TextSpan>{' '}
            into the equation when making decisions about <b>money</b>. <br />
          </TextSpan>
          <TextSlim
            weight={700}
            color="texttransparent"
            size={titleSize}
            className="align-self-start mt-2">
            Our Approach
          </TextSlim>
          <TextSpan
            style={{ display: 'inline' }}
            weight={300}
            color="texttransparent"
            size={bodySize}
            className="align-self-start">
            <b>Mindful awareness of consumerism.</b> If saving was as easy as <i>just</i> spending
            less, everyone would do it. As emotional, irrational creatures, we need more than
            numbers and shame to change. Splurv focuses on the emotional states we're in when we
            desire new things - or have made regrettable purchases - and invites reflection on our
            habits in order to learn from them.
          </TextSpan>
          <TextSlim
            weight={700}
            color="texttransparent"
            size={titleSize}
            className="align-self-start mt-2">
            Our Mission
          </TextSlim>
          <TextSpan
            style={{ display: 'inline' }}
            weight={300}
            color="texttransparent"
            size={bodySize}
            className="align-self-start">
            Bring agency and confidence to people's spending habits by focusing on factors more
            powerful than just money: purpose, time, and emotion. We believe that through loosening
            the shackles on our jobs - by trading materialism and consumerism for simplicity and
            saving - we can live with more intention and balance.
          </TextSpan>
          <TextSlim
            weight={700}
            color="texttransparent"
            size={titleSize}
            className="align-self-start mt-2">
            Our Vision
          </TextSlim>
          <TextSpan
            style={{ display: 'inline' }}
            weight={300}
            color="texttransparent"
            size={bodySize}
            className="align-self-start mb-5">
            Our vision is as simple as it is grand: <br />
            <TextSpan color="logoSecondary" weight={600}>
              To free people from financial overwhelm and empower them to write their own story.
            </TextSpan>
          </TextSpan>
          <div
            style={{
              height: '100px',
            }}
          />
          {/* <TextSlim
            weight={700}
            color="texttransparent"
            size="sm"
            className="align-self-start mt-2">
            You're not on the App Store?
          </TextSlim>
          <TextSpan
            style={{ display: 'inline', marginBottom: '5rem' }}
            weight={300}
            color="texttransparent"
            size="xs"
            className="align-self-start ">
            Aside from technical and business hurdles - long install times, difficulty getting
            update approvals, and 15-30% fees that are charged by Apple and Google App stores - we
            wanted to lower the barrier to entry <i>for you.</i> This <i>is the app.</i> Almost. If
            you click Install and follow the 'Add to Home Screen' prompts, you'll get our app. Sound
            like wizardry? Welcome to <b>Splurv</b> 🎉 🦄
          </TextSpan> */}
        </section>
      </PageContainer>
    </WelcomeContainer>
  )
}
