import React, { useEffect } from 'react'
import { SPLURV_LOCAL_STORAGE_PREFIX } from './useLocalStorageSync'

/**
 * This hook is used to store data in localStorage and sync it with React state
 * @param firstTimeValue The value to initialize the state with
 * @param key The key to use for localStorage
 * @returns [value, setValue, reset]
// TESTED
 */
export type dataParameterType<T> = T | ((prevState: T) => T)
export function useLocalStorageSyncedState<T>(firstTimeValue: T, key: string) {
  const localStorageKey = React.useMemo(() => `${SPLURV_LOCAL_STORAGE_PREFIX}__${key}`, [key])

  const localStorageData = localStorage.getItem(localStorageKey)
  let defaultValue = firstTimeValue

  if (localStorageData) {
    defaultValue = JSON.parse(localStorageData) as T
  }
  const [value, _setValue] = React.useState<T>(defaultValue)

  useEffect(() => {
    const localStorageData = localStorage.getItem(localStorageKey)
    if (localStorageData) {
      const data = JSON.parse(localStorageData) as T
      _setValue(data)
    }
  }, [localStorageKey])

  function setValue(data: dataParameterType<T>) {
    if (typeof data === 'function') {
      _setValue((prevValue: T) => {
        const newValue = (data as (prevState: T) => T)(prevValue)
        localStorage.setItem(localStorageKey, JSON.stringify(newValue))
        return newValue
      })
      return
    }
    localStorage.setItem(localStorageKey, JSON.stringify(data))
    _setValue(data)
  }

  const reset = () => {
    localStorage.removeItem(localStorageKey)
    _setValue(firstTimeValue)
  }

  return [value, setValue, reset] as [T, (v: dataParameterType<T>) => void, () => void]
}
