import { useLocalStorageSyncedState } from 'Hooks/useLocalStorageSyncedState'
import React, { useCallback, useState } from 'react'
import { SPLURV_LOCAL_STORAGE_PREFIX } from '../Hooks/useLocalStorageSync'
import { CreditCardGroupingEnum } from '../Utilities/creditCardSpending/interfaces'
import { Account } from '../Utilities/interfaces'
import { ColorType, colorPalletes } from '../theme'

// import { useFinancialData } from './FinancialDataProvider'

type TrajectoryMode = 'flat' | 'calendar' | 'chart'
type SectionType = 'list' | 'today' | 'reoccuring'
export const CreditCardAverageMethods = [
  'thisMonth',
  'currentStatement',
  'last12Months',
  'today',
  'thisWeek',
  'lastTwoStatements',
] as const
export type CreditCardAverageMethodTuple = typeof CreditCardAverageMethods
export type CreditCardAverageMethod = CreditCardAverageMethodTuple[number]

interface TopLevelState {
  messagePopupContent: string
  messagePopupColors: {
    fgColor: ColorType
    bgColor: ColorType
  }
  popup: React.ReactElement | null
  setPopup: (c: React.ReactElement | null) => void
  setMessagePopupContent: (msg: string) => void
  setMessagePopupColors: ({ fgColor, bgColor }: { fgColor: ColorType; bgColor: ColorType }) => void

  errorPopupContent: string
  setErrorPopupContent: (msg: string) => void

  advancedMode: boolean
  setAdvancedMode: (enabled: boolean) => void
  creditCardAverageMethod: CreditCardGroupingEnum
  setCreditCardAverageMethod: (method: CreditCardGroupingEnum) => void

  activeTheme: keyof typeof colorPalletes
  setActiveTheme: (theme: keyof typeof colorPalletes) => void

  settingsPageOpen: boolean
  setSettingsPageOpen: (val: boolean) => void

  calendarPageOpen: boolean
  setCalendarPageOpen: (val: boolean) => void

  cashFlowPageOpen: boolean
  setCashFlowPageOpen: (val: boolean) => void

  activeCard: Account | null
  setActiveCard: (card: Account) => void

  trajectoryMode: TrajectoryMode
  setTrajectoryMode: (mode: TrajectoryMode) => void

  creditCardAnalysisPage: SectionType
  setCreditCardAnalysisPage: (page: SectionType) => void

  currentBalancePageOpen: boolean
  setCurrentBalancePageOpen: (open: boolean) => void
}

export const defaultData: TopLevelState = {
  messagePopupContent: '',

  messagePopupColors: { fgColor: 'white', bgColor: 'seconddark' },
  popup: null,
  setPopup: () => {},
  setMessagePopupContent: (msg: string) => {},
  setMessagePopupColors: ({ fgColor, bgColor }) => {},

  settingsPageOpen: false,
  setSettingsPageOpen: (val: boolean) => {},

  calendarPageOpen: false,
  setCalendarPageOpen: (val: boolean) => {},

  cashFlowPageOpen: false,
  setCashFlowPageOpen: (val: boolean) => {},

  errorPopupContent: '',
  setErrorPopupContent: (msg: string) => {},
  advancedMode: true,
  setAdvancedMode: (enabled: boolean) => {},
  creditCardAverageMethod: CreditCardGroupingEnum.thisMonth,
  setCreditCardAverageMethod: (method: CreditCardGroupingEnum) => {},
  activeTheme: 'newDark',
  setActiveTheme: (theme) => {},

  activeCard: null,
  setActiveCard: (card: Account) => {},

  trajectoryMode: 'chart',
  setTrajectoryMode: () => {},

  creditCardAnalysisPage: 'today',
  setCreditCardAnalysisPage: (page: SectionType) => {},

  currentBalancePageOpen: false,
  setCurrentBalancePageOpen: (open: boolean) => {},
}

interface Props {
  children: React.ReactNode
}

const UIStateContext = React.createContext<TopLevelState>(defaultData)

export const useUIState = () => React.useContext(UIStateContext)

/**
 * This provider keeps store of any UI state that is mostly ephemeral (pages that are open, UI popups, add card page open/close, settings page open, etc)
 * NOTE: We could likely get rid of a lot of this state as it is not used anymore
 * @param
 * @returns
 */
export const UIStateProvider = ({ children }: Props) => {
  // const { creditCards } = useFinancialData()

  const localStorageTheme = localStorage.getItem(`${SPLURV_LOCAL_STORAGE_PREFIX}_ACTIVE_THEME`)
  if (localStorageTheme && localStorageTheme !== 'undefined') {
    defaultData.activeTheme = JSON.parse(localStorageTheme)
  }

  const localStorageActiveCard = localStorage.getItem(`${SPLURV_LOCAL_STORAGE_PREFIX}_ACTIVE_CARD`)
  if (localStorageActiveCard && localStorageActiveCard !== 'undefined') {
    // defaultData.activeCard = JSON.parse(localStorageActiveCard)
  }
  const [creditCardAnalysisPage, _setCreditCardAnalysisPage] = useState<SectionType>(
    defaultData.creditCardAnalysisPage
  )

  const [activeTheme, _setActiveTheme] = useState<keyof typeof colorPalletes>(
    defaultData.activeTheme
  )

  const setActiveTheme = (activeTheme: keyof typeof colorPalletes) => {
    _setActiveTheme(activeTheme)
    localStorage.setItem(`${SPLURV_LOCAL_STORAGE_PREFIX}_ACTIVE_THEME`, JSON.stringify(activeTheme))
  }

  const [creditCardAverageMethod, setCreditCardAverageMethod] = useState<CreditCardGroupingEnum>(
    defaultData.creditCardAverageMethod
  )

  const [activeCard, _setActiveCard] = useState<Account | null>(defaultData.activeCard)

  const setActiveCard = useCallback(
    (activeCard: Account) => {
      _setActiveCard(activeCard)
      localStorage.setItem(`${SPLURV_LOCAL_STORAGE_PREFIX}_ACTIVE_CARD`, JSON.stringify(activeCard))
    },
    [_setActiveCard]
  )

  const [settingsPageOpen, setSettingsPageOpen] = useLocalStorageSyncedState<boolean>(
    false,
    'user-config-settings-page-open'
  )

  const [calendarPageOpen, setCalendarPageOpen] = useLocalStorageSyncedState<boolean>(
    false,
    'user-config-calendar-page-open'
  )

  const [cashFlowPageOpen, setCashFlowPageOpen] = useLocalStorageSyncedState<boolean>(
    false,
    'user-config-cashflow-page-open'
  )

  const [advancedMode, setAdvancedMode] = useState<boolean>(defaultData.advancedMode)

  const [trajectoryMode, setTrajectoryMode] = useState<TrajectoryMode>(defaultData.trajectoryMode)

  const [currentBalancePageOpen, setCurrentBalancePageOpen] = useState<boolean>(
    defaultData.currentBalancePageOpen
  )

  const setCreditCardAnalysisPage = (page: SectionType) => {
    _setCreditCardAnalysisPage(page)
  }

  const [messagePopupContent, setMessagePopupContent] = useState<string>(
    defaultData.messagePopupContent
  )

  const [popup, setPopup] = useState<React.ReactElement | null>(defaultData.popup)

  const [messagePopupColors, setMessagePopupColors] = useState<{
    fgColor: ColorType
    bgColor: ColorType
  }>(defaultData.messagePopupColors)
  const [errorPopupContent, setErrorPopupContent] = useState<string>(defaultData.errorPopupContent)

  return (
    <UIStateContext.Provider
      value={{
        /*--  VALUES --*/
        // Component Popup
        popup,
        setPopup,
        // Message Popup
        messagePopupColors,
        messagePopupContent,
        // Error Message
        errorPopupContent,
        // Advanced Mode
        advancedMode,
        // Active Theme
        activeTheme,
        // Active Card
        activeCard,
        // Credit Card Average Method
        creditCardAverageMethod,
        // Trajectory Mode
        trajectoryMode,
        // Add Card Page Open
        // Credit Card Analysis Page
        creditCardAnalysisPage,

        // Current Balance Page Open
        currentBalancePageOpen,

        settingsPageOpen,
        setSettingsPageOpen,

        calendarPageOpen,
        setCalendarPageOpen,

        cashFlowPageOpen,
        setCashFlowPageOpen,
        /*--  SETTERS --*/
        // Add Manual Account Page
        // Message Popup
        setMessagePopupColors,
        setMessagePopupContent,
        // Error Message
        setErrorPopupContent,
        // Advanced Mode
        setAdvancedMode,
        // Active Theme
        setActiveTheme,
        // Active Card
        setActiveCard,
        // Credit Card Average Method
        setCreditCardAverageMethod,
        // Trajectory Mode
        setTrajectoryMode,
        // Add Card Page Open
        // Credit Card Analysis Page
        setCreditCardAnalysisPage,
        // Current Balance Page Open
        setCurrentBalancePageOpen,
      }}>
      {children}
    </UIStateContext.Provider>
  )
}
