import confetti from 'canvas-confetti'
import { newColorsDark } from 'theme'

interface ConfettiProps extends confetti.Options {
  /**
   * The number of seconds to be launching confetti
   */
  duration?: number
}

const isDesktop = window.matchMedia('(min-width: 1024px').matches
export const fireConfetti: (props: ConfettiProps) => void = ({
  duration = 1,
  spread = 360,
  particleCount = 10,
  ...rest
}) => {
  var end = Date.now() + duration * 1000

  ;(function frame() {
    confetti({
      particleCount,
      angle: 60,
      spread: spread,
      startVelocity: 30,
      zIndex: 60000,
      gravity: 0.3,
      origin: { x: isDesktop ? 0.4 : 0, y: 0 },
      colors: [newColorsDark.logoPrimary, newColorsDark.second, 'text'],
      ...rest,
    })
    confetti({
      particleCount,
      angle: 120,
      spread: spread,
      startVelocity: 30,
      zIndex: 60000,
      gravity: 0.3,
      origin: { x: 1, y: 0.5 },
      colors: [newColorsDark.logoPrimary, newColorsDark.second, 'text'],
      ...rest,
    })

    if (Date.now() < end) {
      requestAnimationFrame(frame)
    }
  })()
}

export const blastConfetti: (props: ConfettiProps) => void = ({
  duration = 1,
  spread = 360,
  particleCount = 10,
  ...rest
}) => {
  var end = Date.now() + duration * 1000

  ;(function frame() {
    confetti({
      particleCount,
      angle: 60,
      spread: spread,
      startVelocity: 30,
      gravity: 0.3,
      zIndex: 60000,
      origin: { x: isDesktop ? 0.4 : 0, y: 0 },
      colors: [newColorsDark.logoPrimary, newColorsDark.second, 'text'],
      ...rest,
    })
    confetti({
      particleCount,
      angle: 120,
      spread: spread,
      startVelocity: 30,
      zIndex: 60000,
      gravity: 0.3,
      origin: { x: 1, y: 0.5 },
      colors: [newColorsDark.logoPrimary, newColorsDark.second, 'text'],
      ...rest,
    })

    if (Date.now() < end) {
      requestAnimationFrame(frame)
    }
  })()
}
