import color from 'color'
import styled from 'styled-components/macro'
import { ColorType, FontSizeType } from 'theme'

export const FrequencyPlusDateSection = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
`

interface MoneyInputContainerProps {
  label?: string
  fontSizeActive?: FontSizeType
  fontSizeDefault?: FontSizeType
  color?: ColorType
  fullWidth?: boolean
  tight?: boolean
  inputAtLimit?: boolean
  size?: FontSizeType
  secondaryColor?: ColorType
  labelSize?: FontSizeType
  iconSize?: FontSizeType
  icon: string
}

export const SaveButtonRow = styled.div`
  display: grid;
  width: 100%;
  align-items: center;
  grid-template-columns: minmax(0, 1fr) minmax(0, 3fr);
`

export const JumboMoneyInputContainer = styled.div<MoneyInputContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colors[props.secondaryColor || 'textsupertransparent']};
  &:focus-within {
    color: ${(props) =>
      props.color ? props.theme.colors[props.color] : props.theme.colors.second};
  }
  &:active {
    color: ${(props) =>
      props.secondaryColor ? props.theme.colors[props.secondaryColor] : props.theme.colors.second};
  }

  /* max-width: ${(props) => (props.tight ? 'fit-content' : 'inherit')}; */
  /* max-width: ${(props) => (props.tight ? 'fit-content' : 'inherit')}; */
  /* width: 100%; */
  /* width: ${(props) => (props.fullWidth ? '100%' : 'calc(100% - 2.5rem)')}; */

  /* &.disabled {
    &::after {
      opacity: 0.5;
    }
  } */
  input {
    &:focus::placeholder {
      color: transparent;
    }
    padding: 4px;
    width: 100%;
    max-width: 70vw;
    border-style: solid;
    border-width: 0 0 1px;
    border-color: ${(props) => props.theme.colors.nearlyclear};

    transition: all ease-in-out 0.3s;
    border-radius: 0;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:focus {
      color: ${(props) =>
        props.color ? props.theme.colors[props.color] : props.theme.colors.second};
      border-color: ${(props) => props.theme.colors.second};
    }

    font-family: ${(props) => props.theme.fonts.family.secondary};

    font-size: ${(props) => props.theme.fonts.sizes[props?.fontSizeActive || 'xl']};
    color: ${(props) =>
      props.secondaryColor ? props.theme.colors[props.secondaryColor] : props.theme.colors.second};
    :disabled {
      color: ${(props) => color(props.theme.colors.second).alpha(0.5).toString()};
      &::after {
        opacity: 0.5;
      }
    }
    ::placeholder {
      color: ${(props) =>
        color(props.theme.colors[props.secondaryColor || 'nearlyclear'])
          .alpha(0.2)
          .toString()};
    }

    &:focus::after {
      color: ${(props) =>
        props.color ? props.theme.colors[props.color] : props.theme.colors.second};
    }
    &:focus::before {
      color: ${(props) =>
        props.color ? props.theme.colors[props.color] : props.theme.colors.second};
    }
  }
  span {
    position: absolute;
    font-family: ${(props) => props.theme.fonts.family.secondary};
    bottom: -1.75em;
    right: 0;
    color: ${(props) =>
      props.inputAtLimit ? props.theme.colors.mehred : props.theme.colors.textsupertransparent};
  }

  // This is the icon before
  ::before {
    position: absolute;
    color: inherit;
    content: '${({ icon }) => `\\${icon}`}';
    font-family: 'Font Awesome 6 Pro';
    font-size: ${(props) => props.theme.fonts.sizes[props.iconSize || 'lg']};
    transition: all ease-in-out 0.3s;
    display: block;
    font-weight: 700;
    left: -1.5em;
    bottom: 0;
  }

  // this is the label text after
  ::after {
    content: '${({ label }) => `${label}`}';
    position: absolute;
    color: inherit;
    font-family: ${(props) => props.theme.fonts.family.secondary};
    transition: all ease-in-out 0.3s;
    font-size: ${(props) => props.theme.fonts.sizes[props.labelSize || 'xxs']};
    display: block;
    font-weight: 700;
    bottom: -1.75em;
    left: 0;
  }
`

export const MoneyInputContainer = styled.div<MoneyInputContainerProps>`
  position: relative;
  margin-bottom: 3rem;
  margin-left: 1rem;
  display: flex;
  align-items: center;
  min-width: calc(100% - 2.5rem);
  max-width: ${(props) => (props.fullWidth ? 'unset' : 'calc(100% - 2.5rem)')};
  width: ${(props) => (props.fullWidth ? '100%' : 'calc(100% - 2.5rem)')};
  color: ${(props) => props.theme.colors.textsupertransparent};
  &:focus-within {
    color: ${(props) => props.theme.colors[props.color || 'second']};
    input {
      font-size: ${(props) => props.theme.fonts.sizes[props.fontSizeActive || 'sm']};
    }
  }
  &:active {
    color: ${(props) => props.theme.colors[props.color || 'second']};
    input {
      font-size: ${(props) => props.theme.fonts.sizes[props.fontSizeActive || 'sm']};
    }
  }

  /* &.disabled {
    &::after {
      opacity: 0.5;
    }
  } */
  input {
    /* max-width: ${(props) => (props.fullWidth ? 'unset' : 'fit-content')}; */
    width: 100%;
    /* width: ${(props) => (props.fullWidth ? '100%' : 'fit-content')}; */
    &:focus::placeholder {
      color: transparent;
    }
    border-style: solid;
    border-width: 0 0 1px;
    border-color: ${(props) => props.theme.colors.nearlyclear};

    transition: all ease-in-out 0.3s;
    border-radius: 0;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:focus {
      color: ${(props) => props.theme.colors[props.color || 'second']};
      border-color: ${(props) => props.theme.colors[props.color || 'second']};
    }

    font-family: ${(props) => props.theme.fonts.family.secondary};
    font-size: ${(props) => props.theme.fonts.sizes[props.fontSizeDefault || 'xs']};
    color: ${(props) => props.theme.colors[props.color || 'second']};
    color: ${(props) => props.theme.colors.textsupertransparent};
    :disabled {
      color: ${(props) =>
        color(props.theme.colors[props.color || 'second'])
          .alpha(0.5)
          .toString()};
      &::after {
        opacity: 0.5;
      }
    }
    ::placeholder {
      color: ${(props) => props.theme.colors.nearlyclear};
    }

    &:focus::after {
      color: ${(props) => props.theme.colors[props.color || 'second']};
    }
    &:focus::before {
      color: ${(props) => props.theme.colors[props.color || 'second']};
    }
  }

  select {
    border-style: solid;
    border-width: 0 0 1px;
    border-color: ${(props) => props.theme.colors.nearlyclear};

    transition: all ease-in-out 0.3s;
    border-radius: 0;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;

    font-family: ${(props) => props.theme.fonts.family.secondary};
    font-size: ${(props) => props.theme.fonts.sizes.md};
    color: inherit;
    :disabled {
      color: ${(props) => props.theme.colors.textsupertransparent};
    }
    ::placeholder {
      color: ${(props) => props.theme.colors.nearlyclear};
    }
  }

  ::before {
    position: absolute;
    color: inherit;
    content: '${({ icon }) => `\\${icon}`}';
    font-family: 'Font Awesome 6 Pro';
    font-size: ${(props) => props.theme.fonts.sizes['sm']};
    transition: all ease-in-out 0.3s;
    display: block;
    font-weight: 700;
    left: -1.5em;
    bottom: 0;
  }

  ::after {
    content: '${({ label }) => `${label}`}';
    position: absolute;
    color: inherit;
    font-family: ${(props) => props.theme.fonts.family.secondary};
    transition: all ease-in-out 0.3s;
    font-size: ${(props) => props.theme.fonts.sizes['xxs']};
    display: block;
    font-weight: 700;
    bottom: -1.75em;
    left: 0;
  }
`

export const TransitionedPageContainer = styled.div`
  position: absolute;
  padding: 1.5rem;
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  top: 0;
  left: 0;
  max-height: 100vh;
  overflow-y: hidden;

  background: ${(props) => props.theme.colors.background};
  height: 100%;
  width: 100%;
  z-index: 100;
`

export const TransitionedPageContainerInApp = styled.div`
  position: absolute;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  top: 96px;

  /* 100vh 
      - 3rem (padding-top on App.Main for notch) 
      - 3rem (Header grid size) 
      - 6.5rem (Footer grid size)
      - .5rem (Content Padding top size)
       */
  max-height: ${(props) => `calc(100vh - (12.5*16px))`};
  min-height: ${(props) => `calc(100vh - (12.5*16px))`};
  height: ${(props) => `calc(100vh - (12.5*16px))`};
  left: 0;
  overflow-y: hidden;

  background: ${(props) => props.theme.colors.background};
  height: 100%;
  width: 100%;
  z-index: 5;
`

export const TransitionedPageContainerFixedHeight = styled.div<{ maxContainerHeight: number }>`
  position: absolute;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  top: 96px;

  /* 100vh 
      - 3rem (padding-top on App.Main for notch) 
      - 3rem (Header grid size) 
      - 6.5rem (Footer grid size)
      - .5rem (Content Padding top size)
       */
  max-height: ${(props) => `${props.maxContainerHeight}px`};
  min-height: ${(props) => `${props.maxContainerHeight}px`};
  height: ${(props) => `${props.maxContainerHeight}px`};
  left: 0;
  overflow-y: hidden;

  background: ${(props) => props.theme.colors.background};
  height: 100%;
  width: 100%;
  z-index: 5;
`

export const AddEditContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  background: ${(props) => props.theme.colors.background};
  z-index: 1;
  width: 100%;
`

export const AddEditHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`
export const AddEditFooter = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
`

export const ActionButton = styled.button`
  transition: all ease-in-out 0.3s;
  &.loading {
    transition: all ease-in-out 0.3s;
    opacity: 0.5;
    i {
      animation-name: spinner;
      animation-timing-function: ease-in-out;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-delay: 0s;
      @keyframes spinner {
        0% {
          transform: scale(1);
          opacity: 1;
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(359deg);
        }
      }
    }
  }
  i {
    font-size: 1.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  :disabled {
    color: gray;
  }
  :focus {
    outline: none;
  }

  label {
    font-size: 1.5rem;
  }
  display: flex;
  align-items: center;
`

export const ExitButton = styled(ActionButton)`
  color: ${(props) => props.theme.colors.text};
  i {
    font-size: 2.5rem;
  }
`

export const SaveButton = styled(ActionButton)`
  font-size: 1rem;
  color: ${(props) => props.theme.colors.cashgreenlight};
`
export const DeleteButton = styled(ActionButton)`
  color: ${(props) => props.theme.colors.mehredlight};
  font-size: 1rem;
  justify-self: flex-end;
`

export const CreditCardTotalSpan = styled.span`
  color: ${(props) => props.theme.colors.mehredlight};
  font-family: ${(props) => props.theme.fonts.family.secondary};
  font-weight: bold;
`

export const SaveRange = styled.span`
  font-family: ${(props) => props.theme.fonts.family.secondary};
  font-size: 0.75rem;
  display: block;
  margin-top: 2rem;

  span.green {
    color: ${(props) => props.theme.colors.cashgreen};
    font-weight: bold;
  }
`

export const HowDoWeKnowButton = styled.button`
  border-radius: 4px;
  border: solid 2px white;
  padding: 0.375rem;
  margin-top: 1rem;
  font-family: ${(props) => props.theme.fonts.family.secondary};
  font-size: 0.75rem;
  color: ${(props) => props.theme.colors.text}; ;
`

export const FancySelect = styled.select`
  color: ${(props) => props.theme.colors.second} !important;
  font-family: ${(props) => props.theme.fonts.family.secondary};
  font-weight: 400;
  font-size: 1rem;
  outline: none;
  box-shadow: none;
  -webkit-appearance: menulist;
  max-height: 2rem;

  option {
    color: ${(props) => props.theme.colors.second};
  }
`
