import { Transaction } from 'plaid'

export interface CreditCardSpendingDatum {
  total: number
  transactionCount: number
  monthlyAverage: number
  transactions: Transaction[]
}

export enum CreditCardGroupingEnum {
  today = 'today',
  thisWeek = 'thisWeek',
  lastMonth = 'lastMonth',
  thisMonth = 'thisMonth',
  thisStatement = 'thisStatement',
  lastTwoStatements = 'lastTwoStatements',
  thisYear = 'thisYear',
  lastYear = 'lastYear',
  alltime = 'alltime',
}

export const CreditCardGroupingEnumToHumanMap: { [key in CreditCardGroupingEnum]: string } = {
  [CreditCardGroupingEnum.today]: 'Today',
  [CreditCardGroupingEnum.thisWeek]: 'This Week',
  [CreditCardGroupingEnum.lastMonth]: 'Last Month',
  [CreditCardGroupingEnum.thisMonth]: 'This Month',
  [CreditCardGroupingEnum.thisStatement]: 'This Statement',
  [CreditCardGroupingEnum.lastTwoStatements]: 'Last Two Statements',
  [CreditCardGroupingEnum.thisYear]: 'This Year',
  [CreditCardGroupingEnum.lastYear]: 'Last Year',
  [CreditCardGroupingEnum.alltime]: 'All Time',
}

export type CreditCardSpendingBreakdown = {
  [key in CreditCardGroupingEnum]: CreditCardSpendingDatum
}

export type TransactionGrouping = {
  [key in CreditCardGroupingEnum]: number[]
}

export type TransactionGroupingTrasaction = {
  [key in CreditCardGroupingEnum]: Transaction[]
}

export interface CreditCardSpendCacheInterface {
  [index: string]: CreditCardSpendingBreakdown
}
