import { useAuth0 } from '@auth0/auth0-react'
import { isAfter } from 'date-fns'
import React from 'react'

import jwt_decode from 'jwt-decode'

const SPLURV_LOCAL_STORAGE_PREFIX = 'SPLURV_DATA'

/**
 * This is our helper hook that wraps the Auth0 getAccessTokenSilently and then
 * tries caching it locally so that we don't have to call getAccessTokenSilently
 * every time which seems to cause weird issues with the app with forcing a silent
 * auth more times than we would like
 */

export const localTokenStoragePath = `${SPLURV_LOCAL_STORAGE_PREFIX}__auth0__access__token`

export const useGetAccessToken = (caller: string) => {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0()

  const _fetchToken = React.useCallback(async () => {
    console.debug(`<useGetAccessToken> (${caller})  - fetchToken starting`)
    const now = window.performance.now()

    let auth0Token

    try {
      auth0Token = await getAccessTokenSilently()
    } catch (err: any) {
      if (err.error === 'login_required') {
        loginWithRedirect({
          redirectUri: `${window.location.origin}${window.location.pathname}`,
        })
        return
      }
      if (err.error === 'consent_required') {
        loginWithRedirect({
          redirectUri: `${window.location.origin}${window.location.pathname}`,
        })
        return
      }
      console.error('Error from getAccessTokenSilently')
      console.error(err)
      loginWithRedirect({
        redirectUri: `${window.location.origin}${window.location.pathname}`,
      })
      return
    }

    console.debug(`<useGetAccessToken> (${caller})  - fetchToken succeeded returning token`)
    const end = window.performance.now()
    console.debug(`<useGetAccessToken> (${caller})  - PERF _fetchToken took ${end - now}ms`)
    return auth0Token
  }, [loginWithRedirect, getAccessTokenSilently, caller])

  const getAccessToken = React.useCallback(async () => {
    const localStorageToken = localStorage.getItem(localTokenStoragePath)
    if (localStorageToken) {
      console.debug(`<useGetAccessToken> (${caller})  - localStorage token found, checking expiry`)

      const decodedToken = jwt_decode(localStorageToken) as any
      // this is a NumericDate value which is seconds (not ms) since epoch
      // https://stackoverflow.com/questions/39926104/what-format-is-the-exp-expiration-time-claim-in-a-jwt
      const exp = decodedToken.exp
      const tokenExpirationDate = new Date(exp * 1000)
      const tokenExpired = isAfter(new Date(), tokenExpirationDate)
      if (tokenExpired) {
        console.debug(
          `<useGetAccessToken> (${caller})  - localStorage token has expired, fetching new one`
        )

        const auth0Token = await _fetchToken()
        if (auth0Token) {
          localStorage.setItem(localTokenStoragePath, auth0Token)
          return auth0Token
        }
        return ''
      }

      return localStorageToken
    } else {
      console.debug(
        `<useGetAccessToken> (${caller})  - no localStorage token found, fetching via getAccessTokenSilently`
      )
      const auth0Token = await _fetchToken()
      if (auth0Token) {
        localStorage.setItem(localTokenStoragePath, auth0Token)
        return auth0Token
      }
      return ''
    }
  }, [_fetchToken, caller])

  const deleteLocalToken = () => {
    localStorage.removeItem(localTokenStoragePath)
  }

  const forceGetNewRefreshToken = async () => {
    console.debug(
      `<useGetAccessToken> (${caller})  - force refreshing a new access token from Auth0`
    )

    const auth0Token = await _fetchToken()
    if (auth0Token) {
      localStorage.setItem(localTokenStoragePath, auth0Token)
      return auth0Token
    }
  }

  return { deleteLocalToken, getAccessToken, forceGetNewRefreshToken }
}
