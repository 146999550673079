import styled from 'styled-components'

export const AppDarkOverlay = styled.div<{ active: boolean }>`
  background-color: rgba(0, 0, 0, 0.7);
  height: 100vh;
  position: absolute;
  left: 0;
  /* margin-top: -3rem; */
  opacity: ${(props) => (props.active ? 1 : 0)};
  transition: all ease-in-out 0.3s;
  width: 100vw;
  pointer-events: none;
  z-index: 210;
`
