import styled from 'styled-components/macro'

export const Content = styled.div`
  width: auto;
  width: calc(100vw - 4px);
  @media (min-width: 768px) {
    max-width: 70vw;
    left: calc(50% - 35vw);
  }
  height: calc(85%);
  max-height: calc(85%);
  min-height: calc(85%);
  z-index: 500;
  position: absolute;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.colors.logoPrimary};

  background: linear-gradient(135deg, #000c15, #071c23);
  background: black;
  border-bottom: none;

  /* background: ${(props) => props.theme.colors.backgroundPressed}; */
  /* background: linear-gradient(135deg, #b6d8e3, #c8fadc); */
  display: flex;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  margin-left: 2px;
  margin-right: 2px;
`
