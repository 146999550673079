import { useEffect } from 'react'
import { AsyncHookResponse } from './useAsyncFetch'

export const SPLURV_LOCAL_STORAGE_PREFIX = 'SPLURV_DATA'

interface Params<T> {
  method: string
  path: string
  setter: (data: AsyncHookResponse<T>) => void
  data: AsyncHookResponse<T>
}
export function useLocalStorageSync<T extends any>(params: Params<T>) {
  const { method, path, setter, data } = params
  const localStorageKey = `${SPLURV_LOCAL_STORAGE_PREFIX}__${method}-${path}`

  useEffect(() => {
    /* NOTE: Uncomment me if we want to wipe existing data from the UI in the event of error  */
    // if (data.status !== 'idle') {
    /* NOTE: Keep me if we want to preserve data in the UI and only update it if we have new data*/
    if (data.data) {
      setter(data)
      localStorage.setItem(localStorageKey, JSON.stringify(data))
    }
  }, [data, method, path, setter, localStorageKey])

  useEffect(() => {
    const localStorageKey = `${SPLURV_LOCAL_STORAGE_PREFIX}__${method}-${path}`
    const localStorageData = localStorage.getItem(localStorageKey)
    if (localStorageData) {
      const localData = JSON.parse(localStorageData)
      setter(localData)
    }
  }, [method, path, setter])
}
