import { Icon, StyledHr, TextBold, TextSpan } from 'Components/DesignSystem/Typography'
import React from 'react'
import { ThemeProvider } from 'styled-components'
import { colorPalletes, myTheme } from 'theme'

import { useAsyncFetchUnauthenticated } from 'Hooks/useAsyncFetch'
import { JumboMoneyInputContainer } from 'Pages/StreamPage/AddEditStream/components'
import { isMobile } from 'Utilities/utils'
import { InstallButton, PageContainer } from '../PWAcomponents'

import { datadogRum } from '@datadog/browser-rum'
import * as EmailValidator from 'email-validator'

export const SplurvBetaSignupPopup: React.FC = () => {
  const [email, setEmail] = React.useState('')
  const [phone, setPhone] = React.useState('')

  const [emailIsValid, setEmailIsValid] = React.useState(true)
  const [emailIsDuplicate, setEmailIsDuplicate] = React.useState(false)

  const location = window.location
  const source = location.search.split('source=')[1]?.split('&')[0] || 'unknown'

  const betaSignup = useAsyncFetchUnauthenticated<
    { data: {} },
    { source: string },
    {
      email: string
      phone: string
    }
  >({
    method: 'POST',
    route: 'beta_signup',
  })

  const success = betaSignup.res.status === 'done'
  const error = betaSignup.res.status === 'error'

  const asyncColor = error || !emailIsValid ? 'mehred' : success ? 'cashgreen' : 'softblue'

  return (
    <ThemeProvider theme={{ ...myTheme, colors: colorPalletes['newDark'] }}>
      <PageContainer>
        <div className="d-flex flex-column w-100 align-items-start pt-4 px-5">
          <div
            className="d-flex align-items-center justify-content-start w-75 flex-row mt-0 mr-1 ml-2 w-auto"
            style={{
              padding: '1rem',
              borderRadius: '10px',

              background: 'linear-gradient(-135deg, #071c23, #245d74)',
            }}>
            <div className="d-flex align-items-center flex-row">
              <TextBold size="xl" color="logoPrimary" className="m-0 p-0">
                Splurv
              </TextBold>
              <TextBold size="md" color="softblue" className="m-0 p-0 mx-3">
                Wait List
              </TextBold>
            </div>
          </div>

          <TextSpan color="softblue" size="sm" className="mt-4 mb-1">
            We got cool things on the way, and yes that includes more confetti 🎉
          </TextSpan>

          <div className="d-flex align-items-start flex-column ml-4">
            <JumboMoneyInputContainer
              icon="f0e0"
              color="softblue"
              secondaryColor="logoPrimary"
              label="Email*"
              fontSizeDefault={isMobile() ? 'sm' : 'xl'}
              labelSize={isMobile() ? 'sm' : 'lg'}
              iconSize={isMobile() ? 'sm' : 'lg'}
              fontSizeActive={isMobile() ? 'sm' : 'lg'}
              style={{ minWidth: '50%' }}
              className="mt-3 mb-5">
              <input
                data-testid="stream-name-input"
                value={email}
                placeholder={'you@world.com'}
                onChange={(e) => {
                  setEmailIsValid(true)
                  setEmailIsDuplicate(false)
                  setEmail(e.currentTarget.value)
                }}></input>
            </JumboMoneyInputContainer>
            <JumboMoneyInputContainer
              icon="e1e5"
              color="softblue"
              secondaryColor="logoPrimary"
              label="Phone"
              fontSizeDefault={isMobile() ? 'sm' : 'lg'}
              labelSize={isMobile() ? 'sm' : 'lg'}
              iconSize={isMobile() ? 'sm' : 'lg'}
              fontSizeActive={isMobile() ? 'sm' : 'lg'}
              style={{ minWidth: '50%' }}
              className="mt-2 mb-4">
              <input
                data-testid="stream-name-input"
                value={phone}
                inputMode="decimal"
                type="phone"
                placeholder={'1234567890'}
                onChange={(e) => setPhone(e.currentTarget.value)}></input>
            </JumboMoneyInputContainer>
          </div>
          <StyledHr color="softblue" weight={1} className="my-3" style={{ opacity: 0.5 }} />
          <div className={``}>
            <TextSpan
              className="align-self-center mt-3"
              color="text"
              size={isMobile() ? 'xxxs' : 'xxs'}
              style={{
                display: 'inline-block',
                lineHeight: isMobile() ? '14px' : '18px',
              }}>
              By providing my phone number and clicking 'Sign Up', I agree to{' '}
              <b>receive only two text messages</b> at the phone number provided. One upon signing
              up for the wait list and one upon being invited to download the Splurv app.{' '}
              <b>Message and data rates may apply</b>. Reply STOP to cancel and remove yourself from
              the wait list.
            </TextSpan>
          </div>

          <InstallButton
            className="mt-4"
            color={asyncColor}
            onClick={async () => {
              if (!EmailValidator.validate(email)) {
                setEmailIsValid(false)
                return
              }
              try {
                await betaSignup.issueRequest({
                  bodyParamsConfig: {
                    email,
                    phone,
                  },
                  queryParamsConfig: {
                    source,
                  },
                })
                datadogRum.addAction('betaSignup', {
                  betaSignup: {
                    email,
                    phone,
                    source,
                  },
                })
              } catch (err: any) {
                const errMessage = String(err.message)
                console.log(String(err.message))
                if (errMessage.includes('already signed up')) {
                  setEmailIsDuplicate(true)
                }
                console.error(err)
              }
            }}>
            <Icon className="fad fa-party-horn" size="lg" color={asyncColor} />
            <TextSpan size="lg" color={asyncColor}>
              {betaSignup.res.status === 'loading' ? 'Signing you up' : 'Sign Up'}
            </TextSpan>
          </InstallButton>
          <TextSpan
            className="mt-2"
            size="xs"
            style={{
              opacity: error || !emailIsValid || emailIsDuplicate || success ? 1 : 0,
            }}
            color={error || !emailIsValid ? 'mehred' : 'cashgreen'}>
            {emailIsDuplicate
              ? ' That email is already on the list. We will reach out soon. '
              : error && !emailIsDuplicate
              ? "That is truly the worst, we're not sure what happened. Please try again later."
              : !emailIsValid
              ? "That doesn't look like a valid email address"
              : success
              ? "Congrats 🎉 You're on the list. We'll reach out soon."
              : ''}
          </TextSpan>
        </div>
      </PageContainer>
    </ThemeProvider>
  )
}
